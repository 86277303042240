import { useEffect, useState } from 'react'
import './dropdown.css'
import Checkbox from '../Checkbox'
const DropdownItem = ({
   isCheckedAllItems,
   setIsCheckedAllItems,
   items,
   item,
   addItemToMailing,
   removeItemFromMailin,
   selectedItems,
}) => {
   const [itemIsChecked, setItemIsChecked] = useState(false)

   useEffect(() => {
      setItemIsChecked(selectedItems.includes(item))
   }, [selectedItems, item])

   const toggleItemIsChecked = () => {
      itemIsChecked ? removeItemFromMailin(item) : addItemToMailing(item)
      setItemIsChecked(!itemIsChecked)
   }
   return (
      <li className="dropdown-list_item" key={item.id}>
         <div
            className="dropdown-list_item-block"
            onClick={toggleItemIsChecked}
         >
            <Checkbox
               checked={itemIsChecked}
            />
            <span className="dropdown-item_name">
               {item.name || `${item.type}-${item.username}`}
            </span>
         </div>
      </li>
   )
}

export default DropdownItem
